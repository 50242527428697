import { Fragment, useMemo } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";
import { Paths } from "Paths";
import { FormattedMessage } from "react-intl";

const menuItems = [
  {
    name: "Features",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: Paths.Features,
  },
  {
    name: "Devices",
    description: "Speak directly to your customers in a more meaningful way.",
    href: Paths.Devices,
  },
  {
    name: "Pricing",
    description: "Your customers' data will be safe and secure.",
    href: Paths.Pricing,
  },
  {
    name: "Contact",
    description: "Connect with third-party tools that you're already using.",
    href: Paths.Contact,
  },
];

export default function Example() {
  const logo = useMemo(
    () => (
      <svg height="40" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
        <g id="Circle_Grid" data-name="Circle Grid">
          <circle cx="64" cy="64" fill="#111827" r="64" />
        </g>
        <g id="icon">
          <path
            d="m89.935 87.097h1.942v7.63h-1.942z"
            fill="#dbd8dd"
            transform="matrix(.707 -.707 .707 .707 -37.659 90.908)"
          />
          <path
            d="m67.662 46.61h3.544v11.421h-3.544z"
            fill="#dbd8dd"
            transform="matrix(.707 -.707 .707 .707 -16.659 64.422)"
          />
          <rect
            fill="#eeefee"
            height="19.517"
            rx="1.64"
            transform="matrix(.707 -.707 .707 .707 -4.679 69.384)"
            width="31.468"
            x="65.681"
            y="30.582"
          />
          <rect
            fill="#d1d5db"
            height="16.153"
            rx=".239"
            transform="matrix(.707 -.707 .707 .707 -4.679 69.384)"
            width="27.957"
            x="67.435"
            y="32.264"
          />
          <g fill="#eeefee">
            <path
              d="m86.913 25.105h.959v18.268h-.959z"
              transform="matrix(.707 -.707 .707 .707 1.386 71.824)"
            />
            <path
              d="m83.173 28.066h.959v28.883h-.959z"
              transform="matrix(-.707 -.707 .707 -.707 112.746 131.715)"
            />
            <path
              d="m78.811 23.704h.959v28.883h-.959z"
              transform="matrix(-.707 -.707 .707 -.707 108.385 121.186)"
            />
            <path
              d="m82.886 29.132h.959v18.268h-.959z"
              transform="matrix(.707 -.707 .707 .707 -2.641 70.156)"
            />
            <path
              d="m78.859 33.159h.959v18.268h-.959z"
              transform="matrix(.707 -.707 .707 .707 -6.668 68.488)"
            />
            <path
              d="m74.831 37.187h.959v18.268h-.959z"
              transform="matrix(.707 -.707 .707 .707 -10.696 66.82)"
            />
          </g>
          <path
            d="m50.555 63.717h3.544v11.421h-3.544z"
            fill="#dbd8dd"
            transform="matrix(-.707 .707 -.707 -.707 138.42 81.52)"
          />
          <rect
            fill="#eeefee"
            height="19.517"
            rx="1.64"
            transform="matrix(.707 -.707 .707 .707 -45.747 52.373)"
            width="31.468"
            x="24.613"
            y="71.649"
          />
          <path
            d="m26.608 73.331h27.477a.24.24 0 0 1 .24.24v15.674a.239.239 0 0 1 -.239.239h-27.479a.239.239 0 0 1 -.239-.239v-15.673a.24.24 0 0 1 .24-.24z"
            fill="#d1d5db"
            transform="matrix(.707 -.707 .707 .707 -45.747 52.373)"
          />
          <path
            d="m33.766 78.252h.959v18.268h-.959z"
            fill="#eeefee"
            transform="matrix(-.707 .707 -.707 -.707 120.252 124.963)"
          />
          <path
            d="m42.034 69.204h.959v28.883h-.959z"
            fill="#eeefee"
            transform="matrix(-.707 -.707 .707 -.707 13.429 172.854)"
          />
          <path
            d="m37.673 64.843h.959v28.883h-.959z"
            fill="#eeefee"
            transform="matrix(-.707 -.707 .707 -.707 9.068 162.325)"
          />
          <path
            d="m37.793 74.225h.959v18.268h-.959z"
            fill="#eeefee"
            transform="matrix(-.707 .707 -.707 -.707 124.279 115.24)"
          />
          <path
            d="m41.82 70.198h.959v18.268h-.959z"
            fill="#eeefee"
            transform="matrix(-.707 .707 -.707 -.707 128.306 105.517)"
          />
          <path
            d="m45.848 66.17h.959v18.268h-.959z"
            fill="#eeefee"
            transform="matrix(-.707 .707 -.707 -.707 132.334 95.794)"
          />
          <path
            d="m68.341 72.237h15.13v7.35h-15.13z"
            fill="#dbd8dd"
            transform="matrix(.707 -.707 .707 .707 -31.446 75.908)"
          />
          <path
            d="m56.381 40.1-1.581-1.583a11.51 11.51 0 1 0 -16.28 16.283l1.58 1.579z"
            fill="#dbd8dd"
          />
          <path
            d="m82.1 65.82-25.719-25.72-16.281 16.279 25.722 25.721a1.39 1.39 0 0 0 1.972 0l14.306-14.308a1.4 1.4 0 0 0 0-1.972z"
            fill="#eeefee"
          />
          <path
            d="m105 72.646c-4.6-4.6-19.152-4.673-28.088 4.263-.641.641-1.243 1.282-1.825 1.943-7.575 8.522-6.719 21.884-2.448 26.155z"
            fill="#e5e5e5"
          />
          <path
            d="m101.222 76.423c-4.271-4.271-17.623-5.137-26.135 2.428-7.575 8.522-6.719 21.884-2.448 26.155z"
            fill="#eeefee"
          />
          <path
            d="m96.516 92.844a2.6 2.6 0 1 0 0 3.665 2.6 2.6 0 0 0 0-3.665z"
            fill="#dbd8dd"
          />
        </g>
      </svg>
    ),
    []
  );

  return (
    <Popover className="relative bg-white z-10 border-b-2 border-gray-100 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={Paths.Home}>
              <span className="sr-only">Workflow</span>
              <div className="flex items-center text-4xl font-bold">
                {logo}
                <span className="ml-2">Navtrack</span>
              </div>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-600">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Link
              to={Paths.Home}
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              <FormattedMessage id="home" />
            </Link>
            <Link
              to={Paths.Features}
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              <FormattedMessage id="features" />
            </Link>
            <Link
              to={Paths.Devices}
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              <FormattedMessage id="devices" />
            </Link>
            <Link
              to={Paths.Pricing}
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              <FormattedMessage id="pricing" />
            </Link>
            <Link
              to={Paths.Contact}
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              <FormattedMessage id="contact" />
            </Link>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="https://app.navtrack.com"
              className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >
              <FormattedMessage id="header.sign-in" />
            </a>
            <a
              href="https://app.navtrack.com/register"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-slate-700 hover:bg-slate-800"
            >
              <FormattedMessage id="header.sign-up" />
            </a>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>{logo}</div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-600">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {menuItems.map((item) => (
                      <Link
                        key={item.name}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 "
                        to={item.href}
                        onClick={() => close()}
                      >
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  <a
                    href="https://app.navtrack.com/register"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-slate-700 hover:bg-slate-800"
                  >
                    Sign up
                  </a>
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?{" "}
                    <a
                      href="https://app.navtrack.com"
                      className="text-gray-900 hover:text-gray-800"
                    >
                      Sign in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
