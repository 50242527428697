import React from "react";

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
export default function DownloadPage() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Download Navtrack
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Run Navtrack on your machine by using Docker and the following
            commands:
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <ol>
            <li className="font-medium text-gray-700">Install docker.</li>
            <li className="font-medium text-gray-700">
              Get the latest docker-compose.yml.
              <div className="bg-gray-100 p-2 rounded-md font-mono text-sm mt-2">
                curl
                https://raw.githubusercontent.com/navtrack/navtrack/main/docker-compose.yml
                -o docker-compose.yml
              </div>
            </li>
            <li className="font-medium text-gray-700">
              Run Docker Compose.
              <div className="bg-gray-100 p-2 rounded-md font-mono text-sm mt-2">
                docker-compose up
              </div>
            </li>
            <li className="font-medium text-gray-700">
              Open Navtrack in a browser.
              <div className="bg-gray-100 p-2 rounded-md font-mono text-sm mt-2">
                <a href="http://localhost:3000">http://localhost:3000</a>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
