/* This example requires Tailwind CSS v2.0+ */
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { Paths } from "Paths";
import React from "react";
import { Link } from "react-router-dom";

const tiers = [
  {
    name: "Self-hosted",
    href: Paths.Download,
    priceMonthly: 0,
    description:
      "Host Navtrack on your server using Docker, on any server, in any cloud.",
    includedFeatures: ["Live tracking", "Routes", "Log", "Multi-user"],
  },
  {
    name: "Basic",
    href: "https://app.navtrack.com/register",
    priceMonthly: 0,
    description:
      "Start tracking your asset with Navtrack without any subscription.",
    includedFeatures: ["Live tracking"],
    external: true,
  },
  {
    name: "Premium",
    href: "https://app.navtrack.com/register",
    priceMonthly: 5,
    description:
      "Add a subscription to get access to your full history and more.",
    includedFeatures: [
      "Full history",
      "Routes",
      "Log",
      "Reports (coming soon)",
      "Alerts (coming soon)",
      "Webhooks (coming soon)",
      "Support",
    ],
    previous: "Basic",
    external: true,
  },
  {
    name: "Business",
    href: "https://app.navtrack.com/register",
    priceMonthly: 10,
    description: "Enhanced support for your business and multi-user features.",
    includedFeatures: [
      "Advanced reports (coming soon)",
      "Multi-user",
      "Priority support",
    ],
    previous: "Premium",
    external: true,
  },
];

export default function PricingPage() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Start tracking your assets for free and then enable more features by
            adding a subscription.
          </p>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                {/* <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    ${tier.priceMonthly}
                  </span>
                  <span className="text-base font-medium text-gray-500 ml-2">
                    per asset/month
                  </span>
                </p> */}
                {tier.external ? (
                  <a
                    href={tier.href}
                    className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    {tier.name === "Self-hosted" ? "Download" : "Sign up"}
                  </a>
                ) : (
                  <Link
                    to={tier.href}
                    className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    {tier.name === "Self-hosted" ? "Download" : "Sign up"}
                  </Link>
                )}
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-4 space-y-4">
                  {tier.previous && (
                    <li className="flex space-x-3">
                      <ArrowLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
                      <span className="text-sm text-gray-500">
                        Everything in {tier.previous}, plus:{" "}
                      </span>
                    </li>
                  )}
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
