import { useMemo } from "react";
import useDevicesQuery, { Device } from "./queries/useDevicesQuery";

export type DeviceData = {
  devices: Device[];
  protocols: string[];
};

export default function useDevices() {
  const devices = useDevicesQuery();

  const protocols = useMemo(
    () => Array.from(new Set(devices?.data?.map((x) => x.protocol) ?? [])),
    [devices?.data]
  );

  return { devices: devices.data ?? [], protocols };
}
