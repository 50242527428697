import { useForm } from "@formspree/react";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ContactPage() {
  const [state, handleSubmit] = useForm("xknyoqwn");

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Get in touch
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              If you have any questions, suggestions or just want to say hello,
              don't hesitate to contact us.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              {/* <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p className="font-medium">CodeAgency SRL</p>
                  <p>68 Plopilor Street</p>
                  <p>Cluj-Napoca, Cluj, 400383</p>
                  <p>Romania</p>
                </dd>
              </div> */}
              {/* <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex items-center">
                  <div className="w-8 text-center">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <span>
                    <FormattedMessage id="contact.phone-number" />
                  </span>
                </dd>
              </div> */}
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex items-center">
                  <div className="w-8 text-center">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <span>
                    <FormattedMessage id="contact.email" />
                  </span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex items-center">
                  <div className="w-8 text-center">
                    <FontAwesomeIcon icon={faDiscord} />
                  </div>
                  <span>
                    <a
                      href="https://discord.gg/N4ZxhB3y6h"
                      target="_blank"
                      rel="noreffer noreferrer"
                    >
                      Discord
                    </a>
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            {state.succeeded ? (
              <h3 className="text-xl font-semibold tracking-tight text-gray-900">
                Thanks for contact us, we'll get back to you soon.
              </h3>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-1 gap-y-6"
              >
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-700 focus:border-purple-700 border-gray-300 rounded-md"
                    placeholder="Name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-700 focus:border-purple-700 border-gray-300 rounded-md"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-700 focus:border-purple-700 border-gray-300 rounded-md"
                    placeholder="Phone"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-700 focus:border-purple-700 border border-gray-300 rounded-md"
                    placeholder="Message"
                    defaultValue={""}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-slate-700 hover:bg-slate-800 focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
