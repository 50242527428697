import React from "react";
import HomePage from "./components/home/HomePage";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import DevicesPage from "components/DevicesPage";
import { Paths } from "Paths";
import FeaturesPage from "components/FeaturesPage";
import { IntlProvider } from "react-intl";
import translations from "translations";
import Layout from "components/layout/Layout";
import PrivacyPolicyPage from "components/PrivacyPolicyPage";
import TermsConditionsPage from "components/TermsConditionsPage";
import ScrollToTop from "components/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";
import ContactPage from "components/ContactPage";
import PricingPage from "components/PricingPage";
import InstallPage from "components/DownloadPage";

const queryClient = new QueryClient();

export default function App() {
  return (
    <IntlProvider messages={translations.en} locale="en">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route index element={<HomePage />} />
              <Route path={Paths.Pricing} element={<PricingPage />} />
              <Route path={Paths.Features} element={<FeaturesPage />} />
              <Route path={Paths.Download} element={<InstallPage />} />
              <Route path={Paths.Devices} element={<DevicesPage />} />
              <Route path={Paths.Contact} element={<ContactPage />} />
              <Route
                path={Paths.PrivacyPolicy}
                element={<PrivacyPolicyPage />}
              />
              <Route
                path={Paths.TermsConditions}
                element={<TermsConditionsPage />}
              />
            </Routes>
          </Layout>
        </BrowserRouter>
      </QueryClientProvider>
    </IntlProvider>
  );
}
