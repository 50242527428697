import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDevices from "hooks/useDevices";
import { Paths } from "Paths";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import teltonika from "../../assets/images/teltonika.png";
import concox from "../../assets/images/concox.png";
import meitrack from "../../assets/images/meitrack.png";
import atrack from "../../assets/images/atrack.png";
import galileosky from "../../assets/images/galileosky.png";
import gosafe from "../../assets/images/gosafe.png";
import ruptela from "../../assets/images/ruptela.png";
import queclink from "../../assets/images/queclink.png";

export default function HomePageDevices() {
  const intl = useIntl();
  const { devices, protocols } = useDevices();

  const randomCompanies = useMemo(() => {
    const first = { name: "teltonika", image: teltonika };
    const companies = [
      { name: "concox", image: concox },
      { name: "galileosky", image: galileosky },
      { name: "gosafe", image: gosafe },
      { name: "meitrack", image: meitrack },
      { name: "atrack", image: atrack },
      { name: "ruptela", image: ruptela },
      { name: "queclink", image: queclink },
    ];

    for (let i = companies.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const k = companies[i];
      companies[i] = companies[j];
      companies[j] = k;
    }

    return [first, ...companies.slice(0, 5)];
  }, []);

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              <FormattedMessage id="home.supported-devices.title" />
            </h2>
            {devices.length > 0 && (
              <p className="mt-3 max-w-3xl text-lg text-gray-500">
                <FormattedMessage
                  id="home.supported-devices.description"
                  values={{
                    devices: <strong>{devices.length}</strong>,
                    protocols: <strong>{protocols.length}</strong>,
                  }}
                />
              </p>
            )}
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <Link
                  to={Paths.Devices}
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-slate-700 hover:bg-slate-800"
                >
                  <FormattedMessage id="home.supported-devices.view-all" />
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2 select-none">
            {randomCompanies.map((x) => (
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img
                  className="max-h-10"
                  src={x.image}
                  alt={intl.formatMessage({ id: x.name })}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
