export const Paths = {
  Home: "/",
  Pricing: "/pricing",
  PricingOld: "/pricing-old",
  Demo: "/demo",
  Features: "/features",
  Devices: "/devices",
  Download: "/download",
  Contact: "/contact",
  PrivacyPolicy: "/privacy-policy",
  TermsConditions: "/terms-conditions",
};
