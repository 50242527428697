import React from "react";

export default function PrivacyPolicyPage() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-sm text-center text-gray-400 font-semibold tracking-wide uppercase">
              Last updated January 18, 2022
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Privacy policy
            </span>
          </h1>
        </div>
        <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <div>
            <div>
              Thank you for choosing to be part of our community at CodeAgency
              SRL ("<strong>Company</strong>," "<strong>we</strong>," "
              <strong>us</strong>," or "<strong>our</strong>"). We are committed
              to protecting your personal information and your right to privacy.
              If you have any questions or concerns about this privacy notice or
              our practices with regard to your personal information, please
              contact us at support@navtrack.com.
            </div>
            <br />
            <div>
              This privacy notice describes how we might use your information if
              you:
            </div>
            <ul>
              <li>
                Visit our website at{" "}
                <a href="https://navtrack.com">https://navtrack.com</a>
              </li>
              <li>Download and use our mobile application &mdash; Navtrack</li>
              <li>
                Engage with us in other related ways ― including any sales,
                marketing, or events
              </li>
            </ul>
            <div>In this privacy notice, if we refer to:</div>
            <ul>
              <li>
                "<strong>Website</strong>," we are referring to any website of
                ours that references or links to this policy
              </li>
              <li>
                "<strong>App</strong>," we are referring to any application of
                ours that references or links to this policy, including any
                listed above
              </li>
              <li>
                "<strong>Services</strong>," we are referring to our Website,
                App, and other related services, including any sales, marketing,
                or events
              </li>
            </ul>
            <div>
              The purpose of this privacy notice is to explain to you in the
              clearest way possible what information we collect, how we use it,
              and what rights you have in relation to it. If there are any terms
              in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.
            </div>
            <br />
            <div>
              <strong>
                Please read this privacy notice carefully, as it will help you
                understand what we do with the information that we collect.
              </strong>
            </div>
            <br />
            <div>
              <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
            </div>
            <br />
            <div>
              <strong>Personal information you disclose to us</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: We collect personal information that
                you provide to us.
              </em>
            </div>
            <br />
            <div>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services or otherwise when
              you contact us.
            </div>
            <br />
            <div>
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make
              and the products and features you use. The personal information we
              collect may include the following:
            </div>
            <br />
            <div>
              <strong>Personal Information Provided by You.</strong> We collect
              names; email addresses; usernames; passwords; billing addresses;
              contact or authentication data; phone numbers; asset tracking
              data; and other similar information.
            </div>
            <br />
            <div>
              <strong>Payment Data.</strong> We may collect data necessary to
              process your payment if you make purchases, such as your payment
              instrument number (such as a credit card number), and the security
              code associated with your payment instrument. All payment data is
              stored by Stripe. You may find their privacy notice link(s) here:{" "}
              <a href="https://stripe.com/privacy">
                https://stripe.com/privacy
              </a>
              .
            </div>
            <br />
            <div>
              <strong>Social Media Login Data.</strong> We may provide you with
              the option to register with us using your existing social media
              account details, like your Facebook, Twitter or other social media
              account. If you choose to register in this way, we will collect
              the information described in the section called "HOW DO WE HANDLE
              YOUR SOCIAL LOGINS?" below.
            </div>
            <br />
            <div>
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </div>
            <br />
            <div>
              <strong>Information automatically collected</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: Some information &mdash; such as your
                Internet Protocol (IP) address and/or browser and device
                characteristics &mdash; is collected automatically when you
                visit our Services.
              </em>
            </div>
            <br />
            <div>
              We automatically collect certain information when you visit, use
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </div>
            <br />
            <div>The information we collect includes:</div>
            <ul>
              <li>
                <em>Log and Usage Data.</em> Log and usage data is
                service-related, diagnostic, usage and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called 'crash dumps') and hardware settings).
              </li>
              <li>
                <em>Device Data.</em> We collect device data such as information
                about your computer, phone, tablet or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model Internet service provider
                and/or mobile carrier, operating system and system configuration
                information.
              </li>
              <li>
                <em>Location Data. </em>We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Services. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. Note however, if you choose to opt out, you may not be
                able to use certain aspects of the Services.
              </li>
            </ul>
            <br />
            <div>
              <strong>Information collected through our App</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: We collect information regarding your
                geolocation, mobile device, push notifications, when you use our
                App.
              </em>
            </div>
            <br />
            <div>
              If you use our App, we also collect the following information:
            </div>
            <ul>
              <li>
                <em>Geolocation Information.</em> We may request access or
                permission to and track location-based information from your
                mobile device, either continuously or while you are using our
                App, to provide certain location-based services. If you wish to
                change our access or permissions, you may do so in your device's
                settings.
              </li>
              <li>
                <em>Mobile Device Data.</em> We automatically collect device
                information (such as your mobile device ID, model and
                manufacturer), operating system, version information and system
                configuration information, device and application identification
                numbers, browser type and version, hardware model, Internet
                service provider and/or mobile carrier, and Internet Protocol
                (IP) address (or proxy server). If you are using our App, we may
                also collect information about the phone network associated with
                your mobile device, your mobile device&rsquo;s operating system
                or platform, the type of mobile device you use, your mobile
                device&rsquo;s unique device ID and information about the
                features of our App you accessed.
              </li>
              <li>
                <em>Push Notifications.</em> We may request to send you push
                notifications regarding your account or certain features of the
                App. If you wish to opt-out from receiving these types of
                communications, you may turn them off in your device's settings.
              </li>
            </ul>
            <p>
              This information is primarily needed to maintain the security and
              operation of our App, for troubleshooting and for our internal
              analytics and reporting purposes.
            </p>
            <br />
            <div>
              <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: We process your information for
                purposes based on legitimate business interests, the fulfillment
                of our contract with you, compliance with our legal obligations,
                and/or your consent.
              </em>
            </div>
            <br />
            <div>
              We use personal information collected via our Services for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </div>
            <br />
            <div>We use the information we collect or receive:</div>
            <ul>
              <li>
                <strong>
                  To facilitate account creation and logon process.
                </strong>{" "}
                If you choose to link your account with us to a third-party
                account (such as your Google or Facebook account), we use the
                information you allowed us to collect from those third parties
                to facilitate account creation and logon process for the
                performance of the contract. See the section below headed "HOW
                DO WE HANDLE YOUR SOCIAL LOGINS?" for further information.
              </li>
              <li>
                <strong>Request feedback.</strong> We may use your information
                to request feedback and to contact you about your use of our
                Services.
              </li>
              <li>
                <strong>To enable user-to-user communications.</strong> We may
                use your information in order to enable user-to-user
                communications with each user's consent.
              </li>
              <li>
                <strong>To manage user accounts.</strong> We may use your
                information for the purposes of managing our account and keeping
                it in working order.
              </li>
              <li>
                <strong>To send administrative information to you.</strong> We
                may use your personal information to send you product, service
                and new feature information and/or information about changes to
                our terms, conditions, and policies.
              </li>
              <li>
                <strong>To protect our Services.</strong> We may use your
                information as part of our efforts to keep our Services safe and
                secure (for example, for fraud monitoring and prevention).
              </li>
              <li>
                <strong>
                  To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.
                </strong>
              </li>
              <li>
                <strong>To respond to legal requests and prevent harm.</strong>{" "}
                If we receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </li>
              <li>
                <strong>Fulfill and manage your orders.</strong> We may use your
                information to fulfill and manage your orders, payments,
                returns, and exchanges made through the Services.
              </li>
              <li>
                <strong>Administer prize draws and competitions.</strong> We may
                use your information to administer prize draws and competitions
                when you elect to participate in our competitions.
              </li>
              <li>
                <strong>
                  To deliver and facilitate delivery of services to the user.
                </strong>{" "}
                We may use your information to provide you with the requested
                service.
              </li>
              <li>
                <strong>
                  To respond to user inquiries/offer support to users.
                </strong>{" "}
                We may use your information to respond to your inquiries and
                solve any potential issues you might have with the use of our
                Services.
              </li>
              <li>
                T
                <strong>
                  o send you marketing and promotional communications.
                </strong>{" "}
                We and/or our third-party marketing partners may use the
                personal information you send to us for our marketing purposes,
                if this is in accordance with your marketing preferences. For
                example, when expressing an interest in obtaining information
                about us or our Services, subscribing to marketing or otherwise
                contacting us, we will collect personal information from you.
                You can opt-out of our marketing emails at any time (see the
                "WHAT ARE YOUR PRIVACY RIGHTS?" below).
              </li>
              <li>
                <strong>Deliver targeted advertising to you.</strong> We may use
                your information to develop and display personalized content and
                advertising (and work with third parties who do so) tailored to
                your interests and/or location and to measure its effectiveness.
              </li>
            </ul>
            <br />
            <div>
              <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: We only share information with your
                consent, to comply with laws, to provide you with services, to
                protect your rights, or to fulfill business obligations.
              </em>
            </div>
            <br />
            <div>
              We may process or share your data that we hold based on the
              following legal basis:
            </div>
            <ul>
              <li>
                <strong>Consent</strong>: We may process your data if you have
                given us specific consent to use your personal information for a
                specific purpose.
              </li>
              <li>
                <strong>Legitimate Interests</strong>: We may process your data
                when it is reasonably necessary to achieve our legitimate
                business interests.
              </li>
              <li>
                Performance of a Contract: Where we have entered into a contract
                with you, we may process your personal information to fulfill
                the terms of our contract.
              </li>
              <li>
                <strong>Legal Obligations</strong>: We may disclose your
                information where we are legally required to do so in order to
                comply with applicable law, governmental requests, a judicial
                proceeding, court order, or legal process, such as in response
                to a court order or a subpoena (including in response to public
                authorities to meet national security or law enforcement
                requirements).
              </li>
              <li>
                <strong>Vital Interests</strong>: We may disclose your
                information where we believe it is necessary to investigate,
                prevent, or take action regarding potential violations of our
                policies, suspected fraud, situations involving potential
                threats to the safety of any person and illegal activities, or
                as evidence in litigation in which we are involved.
              </li>
            </ul>
            <div>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </div>
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
            </ul>
            <br />
            <div>
              <strong>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short:</strong> If you choose to register or log in
                to our services using a social media account, we may have access
                to certain information about you.
              </em>
            </div>
            <br />
            <div>
              Our Services offers you the ability to register and login using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, profile picture as well as
              other information you choose to make public on such social media
              platform.
            </div>
            <br />
            <div>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use and share your personal information, and how you
              can set your privacy preferences on their sites and apps.
            </div>
            <br />
            <div>
              <strong>5. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: We keep your information for as long
                as necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </em>
            </div>
            <br />
            <div>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </div>
            <br />
            <div>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </div>
            <br />
            <div>
              <strong>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: We aim to protect your personal
                information through a system of organizational and technical
                security measures.
              </em>
            </div>
            <br />
            <div>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our Services is at your own risk.
              You should only access the Services within a secure environment.
            </div>
            <br />
            <div>
              <strong>7. DO WE COLLECT INFORMATION FROM MINORS?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: We do not knowingly collect data from
                or market to children under 18 years of age.
              </em>
            </div>
            <br />
            <div>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent&rsquo;s use of the Services.
              If we learn that personal information from users less than 18
              years of age has been collected, we will deactivate the account
              and take reasonable measures to promptly delete such data from our
              records. If you become aware of any data we may have collected
              from children under age 18, please contact us at
              support@navtrack.com.
            </div>
            <br />
            <div>
              <strong>8. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: In some regions, such as the European
                Economic Area (EEA) and United Kingdom (UK), you have rights
                that allow you greater access to and control over your personal
                information. You may review, change, or terminate your account
                at any time.
              </em>
            </div>
            <br />
            <div>
              In some regions (like the EEA and UK), you have certain rights
              under applicable data protection laws. These may include the right
              (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. To make such a request, please use the contact
              details provided below. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </div>
            <br />
            <div>
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal, nor will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </div>
            <div>&nbsp;</div>
            <div>
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:{" "}
              <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
              .
            </div>
            <br />
            <div>
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:{" "}
              <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                https://www.edoeb.admin.ch/edoeb/en/home.html
              </a>
              .
            </div>
            <br />
            <div>
              If you have questions or comments about your privacy rights, you
              may email us at support@navtrack.com.
            </div>
            <br />
            <div>
              <strong>Account Information</strong>
            </div>
            <br />
            <div>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </div>
            <ul>
              <li>
                Log in to your account settings and update your user account.
              </li>
            </ul>
            <div>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </div>
            <br />
            <div>
              <strong>Opting out of email marketing</strong>: You can
              unsubscribe from our marketing email list at any time by clicking
              on the unsubscribe link in the emails that we send or by
              contacting us using the details provided below. You will then be
              removed from the marketing email list &mdash; however, we may
              still communicate with you, for example to send you
              service-related emails that are necessary for the administration
              and use of your account, to respond to service requests, or for
              other non-marketing purposes. To otherwise opt-out, you may:
            </div>
            <ul>
              <li>Access your account settings and update your preferences.</li>
            </ul>
            <br />
            <div>
              <strong>9. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
            </div>
            <br />
            <div>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </div>
            <br />
            <div>
              <strong>
                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: Yes, if you are a resident of
                California, you are granted specific rights regarding access to
                your personal information.
              </em>
            </div>
            <br />
            <div>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </div>
            <br />
            <div>
              If you are under 18 years of age, reside in California, and have a
              registered account with a Service, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </div>
            <br />
            <div>
              <strong>CCPA Privacy Notice</strong>
            </div>
            <br />
            <div>
              The California Code of Regulations defines a "resident" as:
            </div>
            <br />
            <ol>
              <li>
                every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </li>
              <li>
                every individual who is domiciled in the State of California who
                is outside the State of California for a temporary or transitory
                purpose
              </li>
            </ol>
            <br />
            <div>All other individuals are defined as "non-residents."</div>
            <br />
            <div>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </div>
            <br />
            <div>
              <strong>
                What categories of personal information do we collect?
              </strong>
            </div>
            <br />
            <div>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </div>
          </div>
          <div>&nbsp;</div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>Category</td>
                  <td>Examples</td>
                  <td>Collected</td>
                </tr>
                <tr>
                  <td>Identifiers</td>
                  <td>
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address and account name
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>
                    Personal information categories listed in the California
                    Customer Records statute
                  </td>
                  <td>
                    Name, contact information, education, employment, employment
                    history and financial information
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>
                    Protected classification characteristics under California or
                    federal law
                  </td>
                  <td>Gender and date of birth</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>Commercial information</td>
                  <td>
                    Transaction information, purchase history, financial details
                    and payment information
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>Biometric information</td>
                  <td>Fingerprints and voiceprints</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>Internet or other similar network activity</td>
                  <td>
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems and advertisements
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>Geolocation data</td>
                  <td>Device location</td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </td>
                  <td>
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>Professional or employment-related information</td>
                  <td>
                    Business contact details in order to provide you our
                    services at a business level, job title as well as work
                    history and professional qualifications if you apply for a
                    job with us
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>Education Information</td>
                  <td>Student records and directory information</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>Inferences drawn from other personal information</td>
                  <td>
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual&rsquo;s preferences and
                    characteristics
                  </td>
                  <td>NO</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <br />
            <div>&nbsp;</div>
            <div>
              We may also collect other personal information outside of these
              categories instances where you interact with us in-person, online,
              or by phone or mail in the context of:
            </div>
            <ul>
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <div>How do we use and share your personal information?</div>
            <br />
            <div>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </div>
            <br />
            <div>
              You may contact us by email at support@navtrack.com, or by
              referring to the contact details at the bottom of this document.
            </div>
            <br />
            <div>
              If you are using an authorized agent to exercise your right to
              opt-out we may deny a request if the authorized agent does not
              submit proof that they have been validly authorized to act on your
              behalf.
            </div>
            <br />
            <div>
              <strong>Will your information be shared with anyone else?</strong>
            </div>
            <br />
            <div>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </div>
            <br />
            <div>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal data.
            </div>
            <br />
            <div>
              CodeAgency SRL has not disclosed or sold any personal information
              to third parties for a business or commercial purpose in the
              preceding 12 months. CodeAgency SRL will not sell personal
              information in the future belonging to website visitors, users and
              other consumers.
            </div>
            <br />
            <div>
              <strong>Your rights with respect to your personal data</strong>
            </div>
            <br />
            <div>
              <em>Right to request deletion of the data - Request to delete</em>
            </div>
            <br />
            <div>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation or
              any processing that may be required to protect against illegal
              activities.
            </div>
            <br />
            <div>
              <em>Right to be informed - Request to know</em>
            </div>
            <br />
            <div>Depending on the circumstances, you have a right to know:</div>
            <ul>
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>
                the purposes for which the collected personal information is
                used;
              </li>
              <li>
                whether we sell your personal information to third parties;
              </li>
              <li>
                the categories of personal information that we sold or disclosed
                for a business purpose;
              </li>
              <li>
                the categories of third parties to whom the personal information
                was sold or disclosed for a business purpose; and
              </li>
              <li>
                the business or commercial purpose for collecting or selling
                personal information.
              </li>
            </ul>
            <div>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is not identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </div>
            <br />
            <div>
              <em>
                Right to Non-Discrimination for the Exercise of a
                Consumer&rsquo;s Privacy Rights
              </em>
            </div>
            <br />
            <div>
              We will not discriminate against you if you exercise your privacy
              rights.
            </div>
            <br />
            <div>
              <em>Verification process</em>
            </div>
            <br />
            <div>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </div>
            <br />
            <div>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. If, however, we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity, and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </div>
            <br />
            <div>
              <em>Other privacy rights</em>
            </div>
            <ul>
              <li>you may object to the processing of your personal data.</li>
              <li>
                you may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the data.
              </li>
              <li>
                you can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have been
                validly authorized to act on your behalf in accordance with the
                CCPA.
              </li>
              <li>
                you may request to opt-out from future selling of your personal
                information to third parties. Upon receiving a request to
                opt-out, we will act upon the request as soon as feasibly
                possible, but no later than 15 days from the date of the request
                submission.
              </li>
            </ul>
            <div>
              To exercise these rights, you can contact us by email at
              support@navtrack.com, or by referring to the contact details at
              the bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.
            </div>
            <br />
            <div>
              <strong>11. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
            </div>
            <br />
            <div>
              <em>
                <strong>In Short</strong>: Yes, we will update this notice as
                necessary to stay compliant with relevant laws.
              </em>
            </div>
            <br />
            <div>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </div>
            <br />
            <div>
              <strong>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
            </div>
            <br />
            <div>
              If you have questions or comments about this notice, you may email
              us at support@navtrack.com.
            </div>
            <div>
              <strong>
                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </strong>
            </div>
            <br />
            <div>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please contact us at: support@navtrack.com.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
