import {
  faDiscord,
  faDocker,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircleIcon } from "@heroicons/react/outline";
import CodeAgencyLogo from "components/CodeAgencyLogo";
import { Paths } from "Paths";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

export default function Footer() {
  const intl = useIntl();

  const navigation = useMemo(
    () => ({
      solutions: [
        { name: intl.formatMessage({ id: "features" }), href: Paths.Features },
        { name: intl.formatMessage({ id: "devices" }), href: Paths.Devices },
        { name: intl.formatMessage({ id: "pricing" }), href: Paths.Pricing },
      ],
      company: [
        { name: "About", href: "https://codeagency.com/" },
        { name: "Services", href: "https://codeagency.com/services" },
        { name: "Jobs", href: "https://codeagency.com/jobs" },
      ],
      legal: [
        {
          name: intl.formatMessage({ id: "privacy-policy" }),
          href: Paths.PrivacyPolicy,
        },
        {
          name: intl.formatMessage({ id: "terms-conditions" }),
          href: Paths.TermsConditions,
        },
      ],
      social: [
        {
          name: intl.formatMessage({ id: "github" }),
          href: "https://github.com/navtrack/navtrack",
          icon: faGithub,
        },
        {
          name: intl.formatMessage({ id: "docker" }),
          href: "https://github.com/orgs/navtrack/packages",
          icon: faDocker,
        },
        {
          name: intl.formatMessage({ id: "discord" }),
          href: "https://discord.gg/N4ZxhB3y6h",
          icon: faDiscord,
        },
      ],
    }),
    [intl]
  );

  return (
    <footer className="bg-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <div className="h-10 text-white flex items-center text-2xl">
              <CodeAgencyLogo />
              <span className="ml-2">
                <FormattedMessage id="codeagency" />
              </span>
            </div>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  title={item.name}
                  className="text-gray-400 hover:text-gray-300"
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    size="2x"
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  <FormattedMessage id="navtrack" />
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  <FormattedMessage id="company" />
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  <FormattedMessage id="legal" />
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  <FormattedMessage id="contact" />
                </h3>
                <ul className="mt-4 space-y-4">
                  <li className="text-base text-gray-300">
                    <FormattedMessage id="contact.email" />
                  </li>
                  <li className="text-base text-gray-300">
                    <a
                      href="https://stats.uptimerobot.com/Ps7OKvQ6oN"
                      target="_blank"
                    >
                      Service status
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-700 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()}{" "}
            <a
              href="https://codeagency.com"
              target="_blank"
              rel="noreferrer"
              className="hover:text-gray-300"
            >
              <FormattedMessage id="codeagency" />
            </a>
            {". "}
            <FormattedMessage id="footer.all-rights-reserved" />
          </p>
        </div>
      </div>
    </footer>
  );
}
