import { useQuery } from "react-query";

export type Device = {
  brand: string;
  model: string;
  protocol: string;
  port: string;
};

export default function useDevicesQuery() {
  return useQuery(
    "devices",
    async () => {
      const response = await fetch("/devices.csv");
      const csv = await response.text();

      const devices = convertCsvToDevices(csv);

      return devices;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}

const convertCsvToDevices = (csv: string) => {
  const records = csv.split("\n");
  const devices: Device[] = [];

  for (let i = 0; i < records.length; i++) {
    const record = records[i].split(";");

    devices.push({
      brand: record[1],
      model: record[2],
      protocol: record[3],
      port: record[4],
    });
  }

  return devices.sort((a, b) =>
    `${a.brand} ${a.model} ${a.protocol}`.toLowerCase() <
    `${b.brand} ${b.model} ${b.protocol}`.toLowerCase()
      ? -1
      : 1
  );
};
