import React from "react";

export default function CodeAgencyLogo() {
  return (
    <svg
      height="20"
      viewBox="0 0 60 29"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24.8091 28.3228L38.0846 2.4942C38.7699 1.16094 40.1431 0.322754 41.6422 0.322754H51.1937L37.9182 26.1513C37.2329 27.4846 35.8597 28.3228 34.3606 28.3228H24.8091Z"></path>
      <path d="M11.6832 0.322754C10.3624 0.322754 9.12679 0.974751 8.3813 2.06504L0 14.3228L8.38129 26.5805C9.12678 27.6708 10.3624 28.3228 11.6832 28.3228H21.5385L11.9659 14.3228L21.5385 0.322754H11.6832Z"></path>
      <path d="M51.5 13.3228L60 28.3228H49.8643C46.7993 28.3228 44.8731 25.0172 46.3842 22.3507L51.5 13.3228Z"></path>
    </svg>
  );
}
