/* This example requires Tailwind CSS v2.0+ */
import { faBell } from "@fortawesome/free-regular-svg-icons";
import {
  faDatabase,
  faHdd,
  faMapMarkerAlt,
  faMobileAlt,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDevices from "hooks/useDevices";
import React from "react";

export default function FeaturesPage() {
  const { devices, protocols } = useDevices();

  const features = [
    {
      name: "Live tracking",
      icon: faMapMarkerAlt,
      description: (
        <>
          Real time live tracking for your assets, always showing you the latest
          information received from the devices.
        </>
      ),
    },
    {
      name: "Routes",
      icon: faDatabase,
      description: (
        <>
          View your tracking information split into routes with detailed info
          about each one.
        </>
      ),
    },
    {
      name: "Log",
      icon: faDatabase,
      description: (
        <>
          Access to the raw log of all the information received from the
          devices.
        </>
      ),
    },
    {
      name: "Reports (coming soon)",
      icon: faTable,
      description: (
        <>
          A wide variety of reports will be available, like: total mileage,
          hours driven, number of stops, fuel consumption, etc.
        </>
      ),
    },
    {
      name: "Devices",
      icon: faHdd,
      description: (
        <>
          Navtrack currently supports <b>{devices.length}</b> GPS tracking
          devices on <b>{protocols.length}</b> communication protocols.
        </>
      ),
    },
    {
      name: "Alerts (coming soon)",
      icon: faBell,
      description:
        " Alerts will be available via email or push notification to the Navtrack mobile app for all kinds of location or device events.",
    },
    {
      name: "Mobile app (coming soon)",
      icon: faMobileAlt,
      description:
        "Access to your Navtrack data directly on your phone, coming soon for iOS and Android",
    },
  ];

  return (
    <div className="bg-gray-50">
      <div className="relative py-16 sm:pb-24 sm:pt-10 lg:pb-32 lg:pt-20">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Everything you need to track your assets
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Navtrack is a real time tracking solution that allows you to track
            your
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6 flex">
                  <div className="flow-root bg-gray-100 rounded-lg px-6 pb-8 flex-grow">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-slate-700 rounded-md shadow-lg h-12 w-12">
                          <FontAwesomeIcon
                            icon={feature.icon}
                            className="text-white"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="text-center">
          <p className="mt-8 text-xl text-gray-800">
            Do you have a feature request? Please contact us and share your
            ideas.
          </p>
        </div>
      </div>
    </div>
  );
}
