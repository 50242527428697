import React, { useState, useMemo } from "react";
import useDevices from "hooks/useDevices";
import { FormattedMessage } from "react-intl";

export default function DevicesPage() {
  const [filter, setFilter] = useState("");
  const { devices, protocols } = useDevices();

  const filteredDevices = useMemo(() => {
    if (filter) {
      const filterLower = filter.toLowerCase();

      console.log(filterLower);

      return devices.filter(
        (x) =>
          x.brand.toLowerCase().includes(filterLower) ||
          x.model.toLowerCase().includes(filterLower) ||
          x.protocol.toLowerCase().includes(filterLower) ||
          x.port.toLowerCase().includes(filterLower)
      );
    } else {
      return devices;
    }
  }, [filter, devices]);

  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pb-24 sm:pt-10 lg:pb-32 lg:pt-20">
        <div className="bg-white px-4 py-5 sm:px-6 rounded-lg shadow">
          <div className="text-3xl font-bold text-gray-900 mb-6 text-center">
            Supported GPS tracking devices
          </div>
          <div className="leading-6 text-gray-900  mb-2">
            Navtrack currently supports{" "}
            <span className="font-bold">{devices.length}</span> devices on{" "}
            <span className="font-bold">{protocols.length}</span> communication
            protocols.
          </div>
          <div className="text-md leading-6 text-gray-900 mb-4">
            We are continually looking to add support for new protocols and
            devices, if you are a device manufacturer or if you are looking to
            support for a specific device please contact us.
          </div>
          <div className="text-lg leading-6 text-gray-900 mb-4">
            <input
              type="text"
              placeholder="Filter by brand, model, protocol or port"
              className="shadow-sm focus:ring-slate-700 focus:border-slate-700 block w-full sm:text-sm border-gray-300 rounded-md"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <FormattedMessage id="devices.table.brand" />
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <FormattedMessage id="devices.table.model" />
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <FormattedMessage id="devices.table.protocol" />
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <FormattedMessage id="devices.table.port" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDevices.map((device, index) => (
                      <tr
                        key={`${device.brand}${device.model}${index}`}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {device.brand}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {device.model}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {device.protocol}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {device.port}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
